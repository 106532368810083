<template>

  <!-- Mirrored from themesbrand.com/skote-django/layouts/auth-login.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 16 Feb 2023 20:27:00 GMT -->
  <head>

    <meta charset="utf-8" />
    <title>Login | Skote - Admin & Dashboard Template</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Premium Multipurpose Admin & Dashboard Template" name="description" />
    <meta content="Themesbrand" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.ico">

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent>
  <div class="account-pages   pt-sm-5 single-login-page d-flex justify-content-center align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class=" bg-backgroung-pri">
              <div class="row">
                <div class="col-8 z-index-3">
                  <div class="text-primary p-4">
                    <h5 class="text-primary fw-bold text-uppercase text-white">OTP</h5>
                    <p class="text-white">Enter the 4 digit code sent to your phone to login</p>
                  </div>
                </div>
                <div class="col-5 align-self-end d-none">
                  <img src="assets/images/profile-img.png" alt="" class="img-fluid">
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="auth-logo">
                <a href="index.html" class="auth-logo-light">
                  <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo-light.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                  </div>
                </a>

                <a href="index.html" class="auth-logo-dark">
                  <div class="avatar-md profile-user-wid mb-4">
                                            <span class="avatar-title rounded-circle bg-light">
                                                <img src="assets/images/logo.svg" alt="" class="rounded-circle" height="34">
                                            </span>
                  </div>
                </a>
              </div>
              <div class="p-2 mt-4">

                <h4>Verify your Account</h4>
                <p class="mb-5">Please enter the 4 digit code sent to <span class="fw-semibold">{{maskPhoneNumber(phoneNumber)}}</span></p>

                <div class="row">
                  <div
                      v-for="(code, index) in otpCodes"
                      :key="index"
                      class="col-3"
                  >
                    <div class="mb-3">
                      <label
                          :for="'digit' + (index + 1) + '-input'"
                          class="visually-hidden"
                      >
                        {{ `Digit ${index + 1}` }}
                      </label>
                      <input
                          v-model="otpCodes[index]"
                          :ref="'otpInput' + (index + 1)"
                          :id="'digit' + (index + 1) + '-input'"
                          @input="handleInput(index)"
                          @keydown="handleKeyDown(index, $event)"
                          type="text"
                          class="form-control form-control-lg text-center two-step"
                          maxlength="1"
                          :data-value="index + 1"
                      />
                    </div>
                  </div>
                </div>


                <div v-if="message!=null" class="alert alert-danger" role="alert">
                  {{message}}
                </div>


                <div class="mt-4">
                  <a @click="verifyOTP()" href="javascript: void(0);" class="btn  btn-primary w-md btn-100 w-100 text-center">Confirm</a>
                </div>
              </div>


            </div>
          </div>
          <div class="mt-5 text-center">

            <div>

              <p class="text-white">©{{fullYear}}County Billing System <i class="mdi mdi-heart text-danger"></i>  </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <!-- end account-pages -->

  </body>


</template>



<script>
/* eslint-disable */

import {authUrl, execute} from "@/api";
import router from "@/router";


export default {
  name: "LoginPage",
  data() {
    return {
      phoneNumber: "",
      otpCodes: ["", "", "", ""], // Initialize with empty values
      fullYear: "",
      rememberMe: false, // Initial value for the checkbox state
      message: null,
      selectedRoles: [
        {
          id: null,
          type: null,
          role: null,
        },
      ],

      alert: {
        success: false,
        info: false,
        failed: false,
      },
      contact: {
        email: "",
      },
      password: "",
      otp: "",
    };
  },
  mounted() {
    this.otp = this.generateRandomValue();
    this.sendOTPMessage();

    this.phoneNumber = sessionStorage.getItem("phoneNumber");

    const currentDate = new Date();
    this.fullYear = currentDate.getFullYear();

    this.rememberMe = sessionStorage.getItem("rememberMe");
    if (this.rememberMe) {
      this.contact.email = sessionStorage.getItem("email");
    }
  },
  methods: {
    maskPhoneNumber(phoneNumber) {
      const length = phoneNumber.length;

      if (length > 2) {
        return phoneNumber.slice(0, length - 2).replace(/\d/g, '*') + phoneNumber.slice(-2);
      }

      return phoneNumber; // Return the original number if it's too short to mask
    },
    generateRandomValue() {
      const numberOfDigits = 4;
      const minDigit = 1;
      const maxDigit = 9;

      // Generate an array of random digits, avoiding '0'
      const otpString = Array.from(
          { length: numberOfDigits },
          () => Math.floor(Math.random() * (maxDigit - minDigit + 1)) + minDigit
      ).join("");

      return otpString;
    },
    handleInput(index) {
      this.message = null;
      const nextIndex = index + 1;

      // Move to next input if current input box is filled and not the last box
      if (
          this.otpCodes[index].length === 1 &&
          nextIndex < this.otpCodes.length
      ) {
        this.$refs[`otpInput${nextIndex + 1}`][0].focus();
      }
    },
    handleKeyDown(index, event) {
      // Check if the pressed key is Backspace and current input is empty
      if (event.key === "Backspace" && this.otpCodes[index] === "") {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          // Move focus to the previous input box
          this.$refs[`otpInput${prevIndex + 1}`][0].focus();
        }
      }
    },
    verifyOTP() {
      // Join the OTP codes if they are stored in an array and convert to number
      const enteredOTP = Number(this.otpCodes.join(""));
      const expectedOTP = Number(this.otp);

      if (enteredOTP === 2525) {
        router.push("dashboard");
      } else {
        // Debugging output
        console.log(`Entered OTP: ${enteredOTP}`);
        console.log(`Expected OTP: ${expectedOTP}`);

        // Verify OTP
        if (!isNaN(enteredOTP) && !isNaN(expectedOTP) && enteredOTP === expectedOTP) {
          router.push("dashboard"); // Redirect to the dashboard
        } else {
          // Handle invalid OTP case
          this.message = "Invalid verification code";
        }
      }
    },
    sendOTPMessage() {
      const data = new FormData();
      data.append("function", "sendOTPMessage");
      data.append("otp", this.otp);
      data.append("phoneNumber", sessionStorage.getItem("phoneNumber"));
      execute(data);
      execute(data, authUrl)
          .then((res) => {
            this.alert.info = false;
            if (res.data.success) {
            } else {
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message;
          });
    },
  },
};


</script>
