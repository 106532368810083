<template>


  <head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Business Permits</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
          type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body data-sidebar="dark">


  <!-- Begin page -->
  <div id="layout-wrapper">



    <!-- ========== Left Sidebar Start ========== -->
    <NavigationBar/>
    <!-- Left Sidebar End -->



    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">All Approved Realocations</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item active"><a href="index.html">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="#">Certificates & Licenses</a></li>
                    <li class="breadcrumb-item"><a href="cert-biz-stats.html">Business Permits</a></li>
                    <li class="breadcrumb-item">Pending Validation</li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->
          <div class="row">
            <div class="col-12 active">
              <div class="card active">
                <div class="card-body mini-nav bg-primary pt-2 pb-3 active">
                  <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                    <button class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                      <span class="mdi mdi-menu"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-between active" id="navbarNavAltMarkup">
                      <div class="navbar-nav active">
                        <a class="nav-item nav-link active" href="flagged-transaction-payment-realocation.html" title="Quick stats">Approved Reallocations</a>
                        <a class="nav-item nav-link" href="flagged-transaction-payment-realocation-pending.html" title="Newly registered applications">Pending Approval</a>
                        <a class="nav-item nav-link" href="flagged-transaction-payment-realocation-stats.html" title="applications pending inspection">Reallocated Funds Stats</a>

                      </div>

                      <form class="form-inline my-2 my-lg-0">
                        <button type="button" class="btn btn-info waves-effect waves-light my-2 my-sm-0 text-uppercase" data-toggle="modal" data-target=".new-alcohol-application">Initiate payment Reallocation</button>
                      </form>


                    </div>
                  </nav>
                </div>
              </div>

            </div>
          </div>
          <!-- end of toolbar -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="mx-3">
                        <div class="float-end d-flex text-uppercase">

                          <div class="input-group input-group-sm me-3">
                            <label class="input-group-text">Filter By Initiator</label>
                            <select class="form-select form-select-sm">
                              <option value="JA">Select The Initiator</option>
                              <option value="JA" selected="">All Users</option>
                              <option value="DE">Kelvin Waweru</option>
                              <option value="NO">Amos Were</option>
                            </select>
                          </div>

                          <div class="input-group input-group-sm me-3">
                            <label class="input-group-text">Filter By SubCounty</label>
                            <select class="form-select form-select-sm">
                              <option value="JA">Select The Sub County</option>
                              <option value="JA" selected="">All Sub Counties</option>
                              <option value="DE">Homabay Town</option>
                              <option value="NO">Rachuonyo South</option>
                            </select>
                          </div>


                          <div class="input-group input-group fw-semibold text-uppercase">
                            <label class="input-group-text text-uppercase">From</label>
                            <input class="form-control form-control-sm" type="date" value="2019-08-19" id="example-date-input">
                          </div>

                          <div class="input-group input-group fw-semibold text-uppercase">
                            <label class="input-group-text text-uppercase">To:</label>
                            <input class="form-control form-control-sm" type="date" value="2019-08-19" id="example-date-input">
                          </div>
                        </div>
                      </div>
                      <button data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                      </button>

                    </div>


                  </div>
                  <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                    <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive ">
                    <table class="table table-striped">
                      <thead class="table-dark">
                      <tr class="text-nowrap">
                        <th title="Client Name">Client</th>
                        <th title="Original Invoice Number">Orig. Inv. No.</th>
                        <th title="Original Invoice Date">Orig. Inv. Date</th>
                        <th title="Invoiced Amount">Inv. Amount</th>
                        <th title="Amount Paid to Original Invoice">Amt Paid (Orig. Inv.)</th>
                        <th title="Transferred Invoice Number">Transf. Inv. No.</th>
                        <th title="Transferred Invoice Date">Transf. Inv. Date</th>
                        <th title="Amount Transferred to The other Invoice">Amt Transf.</th>
                        <th title="Reason for Invoice Transfer">Transf. Reason</th>
                        <th title="Supporting Documentation for Transfer">Supporting Docs</th>
                        <th title="Person Who Initiated the Transfer">Initiated By</th>
                        <th title="Phone Number of the Initiator">Initiator Phone</th>
                        <th title="Transfer Initiation Date">Initiated Date</th>
                        <th title="Person Who Approved the Transfer">Approved By</th>
                        <th title="Phone Number of the Approver">Approver Phone</th>
                        <th title="Date the Transfer Was Effected">date initiated</th>
                        <th title="Date of the Transfer">Transf. Date</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tfoot class="table-dark">
                      <tr>
                        <th>22 Clients</th>
                        <th colspan="2">34 Invoices</th>
                        <th>KES 5,600</th>
                        <TH>KES 6,000</TH>
                        <th colspan="2">4 Invoices</th>
                        <TH>KES 6,000</TH>
                        <th></th>
                        <th colspan="9"></th>
                      </tr>
                      </tfoot>
                      <tbody>
                      <tr>
                        <td>ABC Ltd</td>
                        <td>INV-00123</td>
                        <td>1st Jan 2024, 10:30 AM</td>
                        <td>KES 10,000</td>
                        <td>KES 10,000</td>
                        <td>INV-00234</td>
                        <td>5th Jan 2024, 11:00 AM</td>
                        <td>KES 10,000</td>
                        <td>Wrong Invoice Selected</td>
                        <td><a href="#" title="Evidence of Overpayment document">Evidence of Overpayment.pdf</a></td>
                        <td>John Doe</td>
                        <td>+254712345678</td>
                        <td>9th Jan 2024, 9:15 AM</td>
                        <td>Jane Doe</td>
                        <td>+254798765432</td>
                        <td>10th Jan 2024, 12:00 PM</td>
                        <td>10th Jan 2024, 12:45 PM</td>
                        <td class="text-right">
                          <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3 text-uppercase" data-bs-toggle="modal" data-bs-target=".details-modal">Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td>XYZ Enterprises</td>
                        <td>INV-00456</td>
                        <td>2nd Feb 2024, 9:00 AM</td>
                        <td>KES 6,000</td>
                        <td>KES 5,500</td>
                        <td>INV-00567</td>
                        <td>6th Feb 2024, 2:15 PM</td>
                        <td>KES 5,500</td>
                        <td>Incorrect Service Charge</td>
                        <td><a href="#" title="Service Error Invoice document">Service Error Invoice.pdf</a></td>
                        <td>Jane Smith</td>
                        <td>+254700123456</td>
                        <td>11th Feb 2024, 10:30 AM</td>
                        <td>Mark Johnson</td>
                        <td>+254711654321</td>
                        <td>12th Feb 2024, 3:00 PM</td>
                        <td>12th Feb 2024, 4:30 PM</td>
                        <td class="text-right">
                          <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3 text-uppercase" data-bs-toggle="modal" data-bs-target=".details-modal">Details</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
            <!-- end col -->
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <!-- receipting modal -->
      <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
              <span
                  class="badge rounded-pill bg-warning text-uppercase font-12px text-uppercase mx-3">Ongoing Applications</span>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="hori-timeline mt-4">
                    <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                         id="timeline-carousel">
                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                            <h5 class="mb-4">Application</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">Kelvin Omondi</a></p>
                            <p class="text-muted text-capitalize mb-1"><a href="PrintDocs/Receipts/receipts-HOMabay county.html"><i class="bx bx-receipt"></i> View Receipt</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list active">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">-</div>
                            <h5 class="mb-4">Details Verification</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">-</a></p>
                          </div>
                        </div>
                      </div>



                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">-</div>
                            <h5 class="mb-4">Inspection</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">-</a></p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>



                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details </p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                          </thead>
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Name</p>
                            </td>
                            <td class="">Wireless Electronics</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Certificate of Incoporation No. </p>
                            </td>
                            <td class="">1234557768</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                Pin
                                No.</p>

                            </td>
                            <td class="">12345</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>


                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                No.
                              </p>

                            </td>
                            <td class="">12345</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>


                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Activity</p>

                            </td>
                            <td class="">Accomodation and Catering</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr class="text-danger fw-semibold">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Sub category</p>

                            </td>
                            <td class="">Small Lodging House</td>
                            <td class="text-right">
                              <i class="bx bx-x text-danger font-28"></i>
                            </td>

                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="">Corrections Needed/Done <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                          </thead>
                          <tbody>


                          <tr class=" fw-semibold text-capitalize">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Sub category</p>

                            </td>
                            <td class=""><span class="text-primary">change to:</span> large high standard lodging</td>
                            <td class="text-right">
                              <i class="bx bx-x text-danger font-28"></i>
                            </td>

                          </tr>
                          <tr class=" fw-semibold text-capitalize">
                            <td class="text-primary">
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Additional Amount</p>

                            </td>
                            <td class=""><span class="text-primary"></span> <span class="text-primary">KES 12,000</span> (BILL NO. BLL12344jh)</td>
                            <td class="text-right">
                              <i class="bx bx-x text-danger font-28"></i>
                            </td>

                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-user-pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                          </thead>
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Full Names</p>
                            </td>
                            <td class="">Mr Alex Wanjala Akinyi</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                ID/PPT No.</p>
                            </td>
                            <td class="">1234557768</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Fax</p>

                            </td>
                            <td class="">12345</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Mobile No.</p>

                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Alternative Mobile No.</p>

                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>



                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                Address</p>

                            </td>
                            <td class="">12-1004 Nairobi</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <thead class="table-light">
                          <th colspan="3" class="text-right text-uppercase">Verification Status <br> <a href="user-details.html">(By Flex Amondi)</a></th>
                          </thead>
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Mobile No.</p>
                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Alternative Phone No</p>
                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>



                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Email address</p>
                            </td>
                            <td class=""><a
                                href="mailto:email@email.com">email@email.com</a>
                            </td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                county</p>

                            </td>
                            <td class="">Homabay Town Sub county</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                              </p>

                            </td>
                            <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                            </td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Physical address/Street</p>

                            </td>
                            <td class=""><a href="tel:0704549859">Along Moi Avenue
                              opposite cooperative Bank</a></td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Building Name</p>

                            </td>
                            <td class="">ALexis complex</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Stall/Room No.</p>

                            </td>
                            <td class="">122</td>
                            <td class="text-right">
                              <i class="bx bx-check text-success font-28"></i>
                            </td>

                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-8">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-edit-alt pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">License Officer Notes (Flex Amondi)</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="alert alert-warning border-1 border-warning" role="alert">
                        Everything looks OK
                      </div>
                    </div>

                    <div class="col-12">
                      <div>
                        <div class="d-flex flex-column">
                          <label class="mb-0">Validation Remarks</label>
                          <small class="mb-3">Enter your Validation Remarks</small>
                        </div>
                        <textarea name="" id="" cols="30" rows="5" class="form-control mb-2" placeholder="Enter your Remarks"></textarea>
                      </div>
                      <div>
                        <button class="btn w-100 btn-success">Approve for Inspection</button>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="modal-footer d-none">

              <a href="PrintDocs/Receipts/receipts-HOMabay county.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Receipt
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- invoice details -->
      <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-12">
                <address>
                  <strong>Billed To:</strong><br>
                  Kelvin Njuguna<br>
                  email@mail.com, 0704 549 859<br>
                  Hse No. 410, 90 Degrees By Tsavo
                  <br><br>
                  1 Mar 2022, 10:20 AM
                </address>
              </div>
              <div class="col-12">
                <div class="py-2 mt-3">
                  <h3 class="font-size-15 fw-bold">Invoice Details ( <span
                      class="text-primary fw-medium">No. 1237</span> )</h3>
                </div>
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-nowrap">
                    <thead>
                    <tr>
                      <th style="width: 70px;">No.</th>
                      <th>Item</th>
                      <th class="text-end">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>01</td>
                      <td>Rent Deposit</td>
                      <td class="text-end">KES 24,500</td>
                    </tr>

                    <tr>
                      <td>02</td>
                      <td>Monthly Rent</td>
                      <td class="text-end">KES 12,000</td>
                    </tr>

                    <tr>
                      <td>03</td>
                      <td>TAX (18%)</td>
                      <td class="text-end">KES 250</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Total</td>
                      <td class="text-end fw-bold">KES 36,750</td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-end">Paid</td>
                      <td class="text-end  fw-bold">KES 0.00</td>
                    </tr>

                    <tr>
                      <td colspan="2" class="border-0 text-end">
                        <strong>Balance</strong>
                      </td>
                      <td class="border-0 text-end">
                        <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-12 d-none">
                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                  <table class="w-100">
                    <tbody>
                    <tr data-id="1">
                      <td>
                        <label for="" class="">Payment Method</label>
                        <select class="form-control selectpicker w-100 payment-method"
                                data-style="btn-secondary w-100" data-live-search="true"
                                title="Select payment Method">
                          <option value="Mpesa">MPESA</option>
                          <option value="Cash">CASH</option>
                        </select>
                      </td>
                      <td class="px-3 ">
                        <div class="phone-num d-none">
                          <label for="">Phone No.</label>
                          <input type="text " class="form-control w-100 d-flex "
                                 placeholder="Phone No." spellcheck="false"
                                 data-ms-editor="true">
                        </div>

                      </td>
                      <td class="px-3">
                        <label for="">Amount To Be Paid</label>
                        <input type="text " class="form-control w-100 d-flex"
                               placeholder="KES" spellcheck="false" data-ms-editor="true">

                      </td>
                      <td class="text-right float-right">
                        <div class="d-flex flex-column">
                          <label class="opacity-0">Something</label>
                          <a href="#"
                             class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                        </div>
                      </td>


                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="float-end">
                <a href="javascript:window.print()"
                   class="btn btn-success waves-effect waves-light me-1"><i
                    class="mdi mdi-printer font-16px"></i></a>
                <a href="javascript: void(0);"
                   class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
              </div>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice details -->

      <!-- modals starts here -->
      <div class="modal fade new-alcohol-application" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header liq-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Initiate Payment Reallocation</h5>

              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row text-uppercase form-transfer-inputs">

                <div class="col-12 text-capitalize mb-3">
                  <div class="alert alert-info border border-info" role="alert">
                    <p class="text-capitalize d-none">Please note that the original payer will receive a notification with a four-digit code sent to their registered phone number. You need to coordinate with them to obtain this code, as it is required to complete the Payment Reallocation. This step prevents unauthorized transfers and ensures transparency. Ensure clear communication with the payer to keep the process smooth and secure.</p>

                    <p><strong class="text-uppercase">Complete the transfer by following the following steps:</strong></p>
                    <ol>
                      <li><strong>Fill Out the Form:</strong> Complete the Payment Reallocation form accurately, providing all required details.</li>
                      <li><strong>Original Payer Receives Code:</strong> After submission, a four-digit code will be sent to the phone number registered under the original payer’s name.</li>
                      <li><strong>Obtain Confirmation Code:</strong> Contact the original payer to ensure they receive and provide you with this code.</li>
                      <li><strong>Await Final Approval:</strong> Once you have the code, the final approver will review and approve the transfer. Monitor the status until the process is completed.</li>
                    </ol>

                    <p>These steps are crucial to prevent unauthorized transfers and ensure the process remains transparent and secure.</p>

                    <p class="mb-0"><strong>Please confirm that you have read and understood the instructions before proceeding:</strong></p>
                    <label class="mouse-pointer">
                      <input class="form-check-input check-initiato" type="checkbox" id="formCheckcolor3">
                      I have read and understood the instructions regarding the Payment Reallocation process.
                    </label>

                  </div>
                </div>

                <div class="col-12" title="The invoice where the payment was initially made.">
                  <div class="mb-4">
                    <label class="mb-1">Original Invoice Number <strong class="text-danger">*</strong></label>
                    <input v-model="originalBillNo" type="text"  maxlength="25" name="weight" class="form-control" placeholder="Invoice Number where payment was initially made" title="The invoice where the payment was initially made." spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

                <div class="col-12" title="The invoice where the payment was initially made.">
                  <div class="mb-4">
                    <label class="mb-1">Transfer Invoice Number <strong class="text-danger">*</strong></label>
                    <input type="text"  maxlength="25" name="weight" class="form-control" placeholder="Invoice Number the where payments are being transfered to" title="The invoice where the payment was initially made." spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-5">
                    <label for="formFile" class="form-label">Supporting Document</label>
                    <input class="form-control" type="file" id="formFile">
                  </div>
                </div>

                <div class="col-6" title="The new invoice where the payment is being transferred.">
                  <div class="mb-4">
                    <label class="">Supporting Document Title<strong class="text-danger">*</strong></label>
                    <input type="text"  maxlength="25" name="weight" class="form-control" placeholder="Enter the title to the document" title="The new invoice where the payment is being transferred." spellcheck="false" data-ms-editor="true">
                  </div>
                </div>



                <div class="col-12" title="The new invoice where the payment is being transferred.">
                  <div class="mb-4">
                    <label class="mb-1">Amount To Transfer <strong class="text-danger">*</strong></label>
                    <input type="text"  maxlength="25" name="weight" class="form-control" placeholder="KES" title="The new invoice where the payment is being transferred." spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

                <div class="col-12" title="The new invoice where the payment is being transferred.">
                  <div class="mb-4">
                    <label class="mb-1">Transfer Reason <strong class="text-danger">*</strong></label>
                    <input type="text"  maxlength="25" name="weight" class="form-control" placeholder="Enter Reason" title="Enter transfer reason" spellcheck="false" data-ms-editor="true">
                  </div>
                </div>

                <div class="col-12 mt-3 text-uppercase">
                  <button class="btn btn-primary  initiate-realocation-btn text-uppercase">
                    <div class="spinner-border text-white  d-none m-0" height="20px" width="20px" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>

                    <div>Next <i class="bx bx-chevron-right"></i></div>
                  </button>
                </div>
              </div>

              <div class="row transfer-confirmation d-none">
                <div class="col-12 mb-4">
                  <div class="table-responsive border-1 mb-3">
                    <table class="table  vertical-align-middle table-bordered">
                      <thead class="table-danger">
                      <tr class="table-danger">
                        <th ></th>
                        <th colspan="3">ORIGINAL INVOICE: BLL399456985</th>
                      </tr>
                      <tr class="vertical-align-middle">
                        <th style="width: 70px;">1.</th>
                        <th class="text-uppercase" colspan="3">
                          Phone <span class="fw-bold text-primary">BLL123456</span> | Date: <span class="fw-bold text-primary">12/23/2023 12:56 AM</span>  | Invoice For: <span class="fw-bold text-primary">Mama Ngina Shop</span>
                        </th>

                      </tr>
                      </thead>
                      <thead class="bg-light">
                      <tr>
                        <th style="width: 70px;">No.</th>
                        <th colspan="2">Item Description (x QTY)</th>
                        <th class="text-end">Amount</th>

                      </tr>
                      </thead>
                      <tfoot class="bg-light text-uppercase table-bordered">
                      <tr>
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Invoice Sub Total
                        </td>
                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          KES 1,500
                        </td>

                      </tr>
                      <tr>
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Amount Paid
                        </td>
                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          KES 2,500
                        </td>

                      </tr>

                      <tr class="table-success">
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Outstanding Balance
                        </td>
                        <td class="border-0 text-end text-right">
                          <h5 class="m-0 text-uppercase fw-bold">KES -1,000</h5>
                        </td>

                      </tr>
                      </tfoot>
                      <tbody>
                      <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                        <td>01</td>
                        <td colspan="2">
                          <p class="mb-0 p-0 m-0">Bunners (X2)</p>
                        </td>
                        <td class="text-end text-right">KES 1,000</td>

                      </tr>

                      <tr title="Drving a vehicle without a valid inspection certificate inspected">
                        <td>02</td>
                        <td colspan="2">
                          <p class="mb-0 p-0 m-0">Single Business Permit AApplication (X3)</p>

                        </td>
                        <td class="text-end text-right">KES 500</td>

                      </tr>




                      </tbody>
                    </table>
                  </div>

                  <div class="table-responsive border-1 mb-3">
                    <table class="table  vertical-align-middle table-bordered">
                      <thead class="table-info">
                      <tr class="table-info">
                        <th ></th>
                        <th colspan="3" class="text-uppercase">Transfer Invoice Number: BLL399456985</th>
                      </tr>
                      <tr class="vertical-align-middle">
                        <th style="width: 70px;">1.</th>
                        <th class="text-uppercase" colspan="3">
                          Phone <span class="fw-bold text-primary">BLL123456</span> | Date: <span class="fw-bold text-primary">12/23/2023 12:56 AM</span>  | Invoice For: <span class="fw-bold text-primary">Mama Ngina Shop</span>
                        </th>

                      </tr>
                      </thead>
                      <thead class="bg-light">
                      <tr>
                        <th style="width: 70px;">No.</th>
                        <th colspan="2">Item Description (x QTY)</th>
                        <th class="text-end">Amount</th>

                      </tr>
                      </thead>
                      <tfoot class="bg-light text-uppercase table-bordered">
                      <tr>
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Invoice Sub Total
                        </td>
                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          KES 1,500
                        </td>

                      </tr>
                      <tr>
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Amount Paid
                        </td>
                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          KES 0.00
                        </td>

                      </tr>

                      <tr class="table-success">
                        <td></td>

                        <td class="text-right fw-bold text-uppercase" colspan="2">
                          Outstanding Balance
                        </td>
                        <td class="border-0 text-end text-right">
                          <h5 class="m-0 text-uppercase fw-bold">KES 1,500</h5>
                        </td>

                      </tr>
                      </tfoot>
                      <tbody>
                      <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                        <td>01</td>
                        <td colspan="2">
                          <p class="mb-0 p-0 m-0">Bunners (X2)</p>
                        </td>
                        <td class="text-end text-right">KES 1,000</td>

                      </tr>

                      <tr title="Drving a vehicle without a valid inspection certificate inspected">
                        <td>02</td>
                        <td colspan="2">
                          <p class="mb-0 p-0 m-0">Single Business Permit AApplication (X3)</p>

                        </td>
                        <td class="text-end text-right">KES 500</td>

                      </tr>




                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12 mb-4">
                  <div class="alert alert-warning border border-warning" role="alert">
                    <p>After confirming the transfer by clicking the button below labeled <strong>Request Verification Code</strong>, a <strong>4-digit verification code</strong> will be sent to the original payer at <strong>+2547*****859</strong>.
                      Please <strong>contact them</strong> to obtain this code.
                      This code is <strong>required to proceed</strong> with the payment transfer. Once you have the code, you can complete the transfer process.</p>

                    <div class="row">
                      <div class="col-12">
                        <div class="input-group">
                          <button class="btn btn-primary otp-trans-click" type="button" ><i class="mdi  mdi-message-text font-size-16 align-middle me-2"></i> Request Verification Code</button>
                          <input type="text" class="form-control" placeholder="Enter The Verification code sent to customer">
                        </div>
                      </div>

                      <div class="col-12 mt-2">
                        <p>OTP has been sent, Reend otp in <strong class="timer-txt">60 Sec</strong></p>
                      </div>
                    </div>
                  </div>


                  <div class="alert alert-warning border border-warning" role="alert">
                    <strong>Message to be sent to the customer intiating the transfer</strong>

                    <p>
                      Dear Customer,

                      To complete the payment transfer for your invoices, please use the following verification code. Share this code with the county official handling your transfer:

                      Verification Code: [4-Digit Code]

                      Original Invoice No.: [Original Invoice No.]
                      New Invoice No.: [New Invoice No.]

                      If you did not initiate this payment or need assistance, please contact us at [Support Mobile Number].

                      Thank you.
                    </p>

                  </div>

                </div>

                <div class="col-12">
                  <button type="button" class="btn btn-outline-secondary waves-effect me-3 text-uppercase trans-prev"><i class="bx bx-chevron-left fs-4"></i> Previouse</button>
                  <button class="btn btn-success text-uppercase d-none"><i class="bx bx-check me-2 fs-4"></i> Submit Request</button>

                  <button class="btn btn-success  submit-realocation-request text-uppercase">
                    <div class="spinner-border text-white m-0 d-none" height="20px" width="20px" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div class=""><i class="bx bx-check me-2 fs-4"></i> Submit Request</div>
                  </button>
                </div>
              </div>



              <div class="row transfer-request-sent d-flex align-items-center justify-content-center d-none">
                <div class="col-sm-12 col-md-10 col-lg-8">
                  <div class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                    <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                      <div class="avatar-sm rounded-circle bg-info mini-stat-icon">
                                                <span class="avatar-title rounded-circle bg-info">
                                                    <i class="mdi-file-document-edit-outline mdi font-size-24"></i>
                                                </span>
                      </div>
                    </div>


                    <h4 class="text-black fw-bold text-uppercase">ReAllocation Request sent</h4>
                    <p class="text-muted mb-4"> The payment reallocation has been successfully submitted and is now awaiting approval by the final approver. Please follow up on the status to ensure timely approval and completion of the process. </p>                                        <p class="text-muted mb-4">Click Below to downlod the respective Receipts.</p>

                    <div class="d-flex w-100 pb-4">

                      <!-- data-bs-dismiss="modal" -->
                      <a href="#" data-bt-dismiss="modal" aria-bt-label="Close"  class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase download-receipt-transfer">

                        Close
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of madals -->

      <!-- transfer details -->
      <div class="modal fade details-modal" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header liq-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Transfer realocation detail</h5>

              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table table-nowrap vertical-align-middle table-striped table-sm table-bordered">
                      <tbody>
                      <tr title="Invoiced To">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Invoiced To</p>
                        </td>
                        <td class="fw-bold">Kelvin Kiptum</td>
                      </tr>
                      <tr title="Original Invoice Number">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Original Invoice Number (Invoice from which funds are being reallocated)</p>
                        </td>
                        <td class="fw-bold">INV-001234</td>
                      </tr>
                      <tr title="Original Invoice Date">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Original Invoice Date (Date the original invoice was created)</p>
                        </td>
                        <td class="fw-bold">11th Jan 2024, 10:54 AM</td>
                      </tr>
                      <tr title="Invoiced Amount">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Invoiced Amount (Original Invoice)</p>
                        </td>
                        <td class="fw-bold">KES 15,000</td>
                      </tr>
                      <tr title="Amount Paid (Original Invoice)">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount Paid on Original Invoice</p>
                        </td>
                        <td class="fw-bold text-success">KES 12,000</td>
                      </tr>
                      <tr title="Transferred Invoice Number">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Transferred Invoice Number (Invoice to which the funds are being reallocated)</p>
                        </td>
                        <td class="fw-bold text-uppercase">INV-005678</td>
                      </tr>
                      <tr title="Transferred Invoice Creation Date">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Transferred Invoice Creation Date (Date the invoice to which the funds are being reallocated was created)</p>
                        </td>
                        <td class="fw-bold">15th Jan 2024, 12:30 PM</td>
                      </tr>
                      <tr title="Amount Being Transferred">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount Being Transferred</p>
                        </td>
                        <td class="fw-bold">KES 12,000</td>
                      </tr>
                      <tr title="Transfer Serial Number">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Transfer Serial Number</p>
                        </td>
                        <td class="fw-bold">TRX-0907865</td>
                      </tr>
                      <tr title="Reason for Transfer">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Reason for Transfer</p>
                        </td>
                        <td class="fw-bold">Payment allocated to incorrect invoice</td>
                      </tr>
                      <tr title="Supporting Documentation">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Supporting Documentation</p>
                        </td>
                        <td class="fw-bold">Transfer_Approval_Letter.pdf</td>
                      </tr>
                      <tr title="Initiated By">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Initiated By</p>
                        </td>
                        <td class="fw-bold">John Doe</td>
                      </tr>
                      <tr title="Initiator Phone">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Initiator Phone</p>
                        </td>
                        <td class="fw-bold">+254712345678</td>
                      </tr>

                      <tr title="Initiator's Role">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Initiator's Role</p>
                        </td>
                        <td class="fw-bold">Revenue Officer</td>
                      </tr>

                      <tr title="Transfer Initiation Date">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Transfer Initiation Date</p>
                        </td>
                        <td class="fw-bold">15th Jan 2024, 09:00 AM</td>
                      </tr>
                      <tr title="Approved By">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Approved By</p>
                        </td>
                        <td class="fw-bold">Jane Smith</td>
                      </tr>
                      <tr title="Approver Phone">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Approver Phone</p>
                        </td>
                        <td class="fw-bold">+254798765432</td>
                      </tr>
                      <tr title="Approver Role">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Approver Role</p>
                        </td>
                        <td class="fw-bold">Finance Manager</td>
                      </tr>
                      <tr title="Approval Date">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Approval Date (Date the transfer was approved)</p>
                        </td>
                        <td class="fw-bold">15th Jan 2024, 11:00 AM</td>
                      </tr>
                      <tr title="Transfer Date">
                        <td>
                          <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Transfer Date (Date the funds were transferred to the new invoice)</p>
                        </td>
                        <td class="fw-bold">15th Jan 2024, 12:30 PM</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of transfer details -->

      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>
            <div class="col-sm-6 ">
              <div class="text-sm-end d-sm-block ">
                County Billing System
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>




  </body>


</template>
<script>
/* eslint-disable */

import NavigationBar from "@/components/Navigation";
import {paysol, execute, execute2, biller, getRight, trade, parking, rent} from "@/api";
import moment from "moment/moment";
export default {
  name: "printReceipt",
  components: {NavigationBar},
  data() {
    return {
      originalBillNo:'',
      formatType:'',
      bankType:'',
      bankID:'',
      recon: true,
      dateTo:'',
      dateFrom:'',
      statement:'',
      message:null,
      loading: false,
      selectedFile: null,
      alert:{
        success: false,
        info: false,
        failed:false
      },
      uploads: [
        {
          id: null,
          reconCode: null,
          shortCode: null,
          fromDate: null,
          toDate: null,
          totalRecords:null,
          missingTransactions: null,
          file: "/",
          dateCreated: null,
          transactionType: null,
        }
      ],
      banks: [
        {
          id: null,
          bankID:null,
          bank: null,
          payBill: null,
          format:  null
        }],
      billDetails: {
        id: null,
        billNo: null,
        incomeTypeID: null,
        incomeTypeDescription: null,
        costCenterNo: null,
        accountNo: null,
        feeID: null,
        detailAmount: null,
        amountPaid: null,
        balance: null,
        status: null,
        dateCreated: null,
      },
      billInfo: [],
    };
  },
  mounted() {

    /*  this.intervalId = setInterval(() => {

      }, 10000); // 10000 milliseconds = 10 seconds*/

    this.uploads.splice(0)

    this.getUploadedTransactions();
    this.menuAccess()
    this.getBanks()

    this.$watch('originalBillNo', function () {
      this.getOriginalBillNo()
    })

  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to avoid memory leaks
    clearInterval(this.intervalId);
  },
  methods: {
    getOriginalBillNo() {
      const data = new FormData();
      data.append("function", "getBill");
      data.append("billNo", this.originalBillNo);
      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.billDetails = res.data.data.billDetails;
              this.billInfo = res.data.data.billInfo;
            } else {
              alert(res.data.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    getRight,
    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Recon");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },
    formatDateTime(dateTimeStr) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const date = new Date(dateTimeStr);

      if (isNaN(date.getTime())) {
        return 'Invalid Date';
      }

      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12; // Handle midnight (00:00) as 12 AM

      const formattedDateTime = `${day} ${month} ${year} At ${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
      return formattedDateTime;
    },
    showDate() {
      const dateObj = new Date();
      const currentDate =
          dateObj.getDate() +
          "/" +
          dateObj.getMonth() +
          "/" +
          dateObj.getFullYear();
      return currentDate;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    handleFileChange: function (event) {
      console.log("File selected:", event.target.files[0]);
      this.selectedFile = event.target.files[0];
    },
    uploadExcelToQue() {
      this.recon = false
      this.alert.failed = false
      this.alert.success = false
      console.log("Upload button clicked");
      console.log("Selected File in uploadExcelToQue:", this.selectedFile);
      this.loading = true
      // Make sure 'file' is a valid File object

      var stream = null;

      const data = new FormData();
      if(this.formatType === "MPESA"){//2 is MPESA
        stream = paysol;
        data.append("function", "uploadExcelToQue");
      }else {
        stream = biller;
        data.append("function", "uploadBank");
        data.append("formatType", this.formatType);
        data.append("bankType", this.bankType);
        data.append("bankID", this.bankID);
      }
      data.append("fileToUpload", this.selectedFile);
      data.append("dateFrom", this.dateFrom);
      data.append("dateTo", this.dateTo);
      data.append("createdBy", sessionStorage.getItem("sessionNames"));
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));

      // Assuming 'pay' is defined
      execute2(data,stream)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.recon = false

              this.getUploadedTransactions();

              this.alert.success = true
              this.message =res.data. message

            } else {
              this.recon = true
              // Handle failure case
              this.alert.failed = true
              this.message =res.data. message
            }
          })
          .catch((e) => {
            // Handle network or other errors
            // alert(e.message);
            // this.alert.failed = true
          });
    },

    closeModal() {
      // Add any additional logic you need before closing the modal
      // For example, you may want to reset some data or perform an action
      // Then, close the modal
      $('#uploadFile').modal('hide'); // Assuming you're using Bootstrap modal
    },

    getUploadedTransactions() {
      const data = new FormData();
      data.append("function", "getBill");
      // Assuming 'pay' is defined
      execute2(data,biller)
          .then((res) => {
            if (res.data.success) {
              this.loading = false
              this.uploads = res.data.data.uploads
            } else {
              this.loading = false
            }
          })
          .catch((e) => {

          });
    },
    getBanks() {

      const data = new FormData();
      data.append("function", "getBanks");
      // Assuming 'pay' is defined
      execute(data,biller)
          .then((res) => {
            if (res.data.success) {
              this.banks.splice(0)
              this.banks = res.data.data.banks

            } else {
              alert(res.data.message)
            }
          })
          .catch((e) => {

          });
    },
    getFormat(event) {

      const json = JSON.parse(event.target.value)
      this.formatType = json.format
      this.bankType = json.bankType
      this.bankID = json.bankID
    },

    getFileName(filePath){

      const parts = filePath.split('/');

// Get the last part (the filename)
      const filename = parts[parts.length - 1];

      return filename;

    },

    gotTo(route,reconCode){
      sessionStorage.setItem("reconCode",reconCode)
      this.$router.push(route);

    },

    formattedTime(time) {
      return moment(time).format('hh:mm A')
    }


  },
};
</script>


<style scoped>

</style>