<template>

    <head>

        <meta charset="utf-8" />
        <title>Desk Birdy | Parking Quick Stats</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description" />
        <meta content="Kelvin Njuguna" name="author" />
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css" />

        <!-- // Add the new slick-theme.css if you want the default styling -->
        <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
        <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
        <!-- App Css-->

        <!-- owl.carousel css -->
        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

    </head>

    <body @contextmenu.prevent data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->
    <!-- Loader -->

    <!-- Begin page -->

    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="content-minimize">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row ">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Daily Parking</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Parking</a></li>
                                        <li class="breadcrumb-item"><a href="#">Weekly Parking</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->
                    <ToolBar/>
                    <!-- end of toolbar -->
                    <div class="row">
                        <div class="col-lg-12 px-sm-30px">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-sm-12 align-self-center text-capitalize">
                                            <div class="text-lg-left mt-4 mt-lg-0">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-warning font-size-20">
                                                                    <i class="mdi mdi-car-multiple text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Logged Vehicles</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.logged)}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-info font-size-20">
                                                                    <i
                                                                            class="mdi-cash-check mdi text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2 text-capitalize">
                                                                Parking Fee Payers</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.payers)}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span class="avatar-title rounded-circle font-size-20">
                                                                    <i class="mdi mdi-check-all  text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Compliant Vehicles</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.compliant)}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span class="avatar-title rounded-circle font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Uncompliant Vehicles</p>
                                                            <h5 class="mb-0">{{numberFormat(stats.uncompliant)}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-danger font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Clamped Vehicles
                                                            </p>
                                                            <h5 class="mb-0">{{numberFormat(stats.clamped)}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-2 col-lg-2 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-danger font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">To Be Unclamped
                                                            </p>
                                                            <h5 class="mb-0">{{numberFormat(stats.tobeunclamped)}}</h5>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white  w-100 border-bottom">
                                    <h4 class="card-title text-capitalize">
                                        Bus Park Parking Report for The period {{formatDate(dateFrom)}} to {{formatDate(dateTo)}}
                                    </h4>
                                </div>
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <div class="flex-grow-1">
                                            <div class="search-box mb-0 me-3">
                                                <div class="position-relative">
                                                    <input v-model="search" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                                                    <i class="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">


                                        <div class="d-flex">

                                            <select class="form-select w-auto font-13px mx-3" v-model="subCounty" @change="getClampedEntries(subCounty.subCountyName)">

                                                <option  selected="" value="">All Sub Counties</option>
                                                <option :key="index" :value="item" v-for="(item, index) in subCounties">
                                                    {{ item.subCountyName }}
                                                </option>

                                            </select>


                                            <select class="form-select w-auto font-13px mx-3" v-model="ward" @change="getZones(ward.wardID, ward.wardName)">
                                                <option  selected="" value="">All Wards</option>
                                                <option :key="index" :value="item"
                                                        v-for="(item, index) in wards">
                                                    {{ item.wardName }}
                                                </option>

                                            </select>



                                            <select class="form-select w-auto font-13px mx-3" v-model="zone" @change="getUsers(zone)" >

                                                <option selected="" value="">All Zones</option>
                                                <option v-for="(item, index) in zones" :key="`${index}`"><a class="dropdown-item" href="#">{{item.zone}}</a></option>

                                            </select>


                                            <select class="form-select w-auto font-13px mx-3" v-model="idNo" @change="getClampedEntries(idNo)">
                                                <option selected=""  >All Attendants</option>
                                                <option v-for="(item, index) in users" :key="`${index}`" :value="item.idNo"><a class="dropdown-item" href="#">{{item.names}}</a></option>

                                            </select>

                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">From:</span>
                                                <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                                                <span class="px-3 fw-semibold">TO:</span>
                                                <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">
                                            </div>
                                            <button @click="downloadClampedEntries()" data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                            </button>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">

                                        <table class="table align-middle table-striped table-nowrap table-hover dt-responsive contacts-table" id="">
                                            <thead class="table-dark">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Vehicle</th>
                                                <th>Category</th>
                                                <th>Sub County</th>
                                                <th scope="col">Location</th>
                                                <th scope="col" class="">Clamping Date</th>
                                                <th>Clamping Reason(s)</th>
                                                <th scope="col" class="">Unclamped On</th>
                                                <th scope="col" class="text-right">Total Charges</th>
                                                <th>Payment Status</th>
                                                <th>Clamping Status</th>
                                                <th>Clamped By</th>
                                                <th class="text-right">Amount Paid</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody class="text-capitalize">
                                            <tr :key="index" :value="item" v-for="(item, index) in vehicles">
                                                <th>{{index+1}}.</th>
                                                <th>{{item.numberPlate}}</th>
                                                <td class="text-capitalize">{{item.feeDescription}}</td>
                                                <td>
                                                    {{item.zone}}
                                                </td>
                                                <td>{{item.address}}</td>
                                                <td>
                                                    {{formattedDate(item.dateCreated)}}

                                                </td>
                                                <td>{{item.description}}</td>
                                                <td>
                                                    {{item.clampedTime}}
                                                </td>
                                                <td class="text-right">
                                                    KES {{numberFormat(item.detailAmount)}}
                                                </td>
                                                <td>
                                                    <span v-if="item.status==='PAID'" class="badge badge-soft-success rounded-pill fw-bold text-uppercase" key="t-new">{{item.status}}</span>
                                                    <span v-if="item.status !=='PAID'" class="badge badge-soft-danger rounded-pill fw-bold text-uppercase" key="t-new">{{item.status}}</span>
                                                </td>

                                                <td>
                                                    <span v-if="item.clampedStatus==='0'" class="badge rounded-pill bg-warning text-uppercase" key="t-new">To be clamped</span>
                                                    <span  v-if="item.clampedStatus==='1'"  class="badge rounded-pill bg-danger text-uppercase" key="t-new">Clamped</span>
                                                    <span  v-if="item.clampedStatus==='2'"  class="badge rounded-pill bg-success text-uppercase" key="t-new">to be (Unclamp)</span>
                                                    <span  v-if="item.clampedStatus==='3'"  class="badge rounded-pill bg-success text-uppercase" key="t-new"> Unclamped</span>
                                                </td>
                                                <td>{{item.names}}</td>
                                                <td class="text-right fw-semibold">
                                                    KES {{numberFormat(item.receiptAmount)}}
                                                </td>
                                                <td class="text-right">
                                                    <button @click="getClampingProgress(item)" data-toggle="modal" data-target=".add-car-modal" class="btn btn-success btn-sm">Details</button>
                                                    <button @click="getClampingProgress(item)" class="btn btn-danger mx-3 btn-sm" data-target=".manual-unclamp" data-toggle="modal">Manual Unclamping</button>
                                                </td>
                                            </tr>
                                            </tbody>


                                        </table>

                                        <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>

                                        <div v-if="vehicles.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                        </div>


                                    </div>
                                </div>
                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example " class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px" v-model="pageSize">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                                <option value="5000">5000 Rows</option>
                                                <option value="10000">10000 Rows</option>
                                                <option value="100000000000">All Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                <a class="page-link" tabindex="-1" @click="prevPage" :disabled="currentPage === 1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li v-for="page in pagination(currentPage, totalPages, 3)" :key="page" class="page-item" :class="{ active: currentPage === page }">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span v-if="page === '...'" class="page-link">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a v-else class="page-link" @click="goToPage(page)">{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                                <a class="page-link"  @click="nextPage" :disabled="currentPage === totalPages">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->
            <div class="modal fade manual-unclamp" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered ">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Manually Unclamp {{vehicle.numberPlate}}</h5>

                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <p>Please provide the following details to manually release the clamp on this vehicle. Please be aware that the action will be attributed to your name.</p>
                                </div>
                                <div class="col-12">
                                    <div>
                                        <label class="form-label">Reason For Manually Unclamping</label>
                                        <textarea v-model="reason" name="" class="form-control" placeholder="Enter Reason"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button @click="unclampVehicle()" data-dismiss="modal" class="btn btn-primary btn-100 w-100">UNCLAMP</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>

            <!-- invoice details -->
            <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg  modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Clamping Details</h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>

                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-4">
                                    <div class="hori-timeline mt-4">

                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">

                                            <div class="item event-list" v-for="(item, index) in clampingprogress" :value="item" :key="index" style="display: inline-block; width: 350px">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">{{formattedDate(item.dateCreated)}}</div>
                                                        <h5 class="mb-4">{{item.status}}</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="javascript: void(0);">{{item.description}}</a></p>
                                                        <p class="text-muted text-capitalize mb-1"><i class="bx bx-map"></i>{{item.location}}</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >

                                <div class="new-vehicles-container-modal">
                                    <div class="col-12 d-none">
                                        <div class="py-2 mt-3">
                                            <h3 class="font-size-15 fw-bold">Details on this clamping</h3>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="">
                                            <table class="table text-capitalize  table-striped mb-3 table-sm">

                                                <tbody>
                                                <tr>
                                                    <td >Plate No</td>
                                                    <th class="text-uppercase">{{vehicle.numberPlate}}</th>
                                                </tr>
                                                <tr>
                                                    <td>Vehicle Category</td>
                                                    <th>{{vehicle.feeDescription}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Sub County, Ward, Zone </td>
                                                    <td>{{vehicle.subCountyName}}, {{vehicle.wardName}}, {{vehicle.zone}} </td>
                                                </tr>

                                                <tr>
                                                    <td>Address</td>
                                                    <th>{{vehicle.address}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Date Registered Due for Clamping</td>
                                                    <th>{{formattedDate(vehicle.dateCreated)}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Scanned By</td>
                                                    <th>{{vehicle.names}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Clamped On</td>
                                                    <th>{{formattedDate(vehicle.clampedTime)}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Reason For Clamping</td>
                                                    <th>{{vehicle.description}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Clamped By</td>
                                                    <th>{{vehicle.names}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Total Fee Payable</td>
                                                    <th>KES {{numberFormat(vehicle.detailAmount)}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Payment Status</td>
                                                    <th v-if="vehicle.status==='PAID'" class="text-uppercase text-success">{{vehicle.status}}</th>
                                                    <th v-if="vehicle.status !=='PAID'" class="text-uppercase text-danger">{{vehicle.status}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Current Clamping Status</td>
                                                    <th v-if="vehicle.clampedStatus==='0'" class="text-uppercase text-success">to be Clamped</th>
                                                    <th v-if="vehicle.clampedStatus==='1'" class="text-uppercase text-success">clamped</th>
                                                    <th v-if="vehicle.clampedStatus==='2'" class="text-uppercase text-success">unclamp</th>
                                                    <th v-if="vehicle.clampedStatus==='3'" class="text-uppercase text-success">unclamp</th>
                                                </tr>

                                                <tr>
                                                    <td>Amount Paid</td>
                                                    <th >KES {{numberFormat(vehicle.receiptAmount)}}</th>
                                                </tr>

                                                <tr>
                                                    <td>Reason for unclamping</td>
                                                    <th> {{vehicle.reason}}</th>
                                                </tr>

                                                <tr>
                                                    <td>uUnclamped by </td>
                                                    <th> {{vehicle.unclampedBy}} <a href="tel: {{vehicle.phoneNumber}}">({{vehicle.phoneNumber}})</a></th>
                                                </tr>

                                                </tbody>

                                            </table>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->
            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">

                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                County Government
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>
</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/parking/ToolBar";
    import {formatDate,authUrl, biller, execute, executeDownload, parking} from "@/api";
    export default {
        name: "DailyParking",
        components: {ToolBar, NavigationBar},
        data(){
            return{
                reason:'',
                idNo:'',
                subCountyName:'',
                wardName:'',
                dateFrom:'',
                dateTo:'',
                download:'Download Report',
                numberPlate:'',
                search:'',
                loading: false,
                // set the initial current page as 1
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                summary: {
                    count: 0,
                    total: 0
                },
                saccoName:'',
                complianceStatus:'',
                totalAmount:0,
                vehicle: {
                    id: "538",
                    billNo: "PKN010410131664",
                    detailAmount: "550",
                    receiptAmount: "550",
                    billBalance: "0",
                    numberPlate: "KDA031C",
                    lat: "0.0465181",
                    lng: "37.6536924",
                    address: "2MW3+6R2, Njuri Ncheke Street, Meru, Kenya",
                    idNo: "37751269",
                    names: "Felix Bundi",
                    phoneNumber: "254740030432",
                    dateCreated: "2024-01-04 10:13:16",
                    status: "PAID",
                    zone: "MERU TOWN PARKING",
                    subCountyID: "120591",
                    subCountyName: "MERU TOWN",
                    wardID: "120591291",
                    wardName: "MERU MUNICIPALITY",
                    accountDesc: "Daily parking",
                    feeDescription: "Saloon Car",
                    transactionCode: null,
                    paidBy: null,
                    paymentMode: null,
                    clampedStatus: "2",
                    clampedTime: "2024-01-04 10:18:16",
                    customerPhoneNumber: null,
                    description:null,
                    reason:null,
                    unclampedBy:null,
                    unclampedByPhone:null,
                    unclampedByIdNo:null
                },
                vehicles: [
                    {
                        id: "538",
                        billNo: "PKN010410131664",
                        detailAmount: "550",
                        receiptAmount: "550",
                        billBalance: "0",
                        numberPlate: "KDA031C",
                        lat: "0.0465181",
                        lng: "37.6536924",
                        address: "2MW3+6R2, Njuri Ncheke Street, Meru, Kenya",
                        idNo: "37751269",
                        names: "Felix Bundi",
                        phoneNumber: "254740030432",
                        dateCreated: "2024-01-04 10:13:16",
                        status: "PAID",
                        zone: "MERU TOWN PARKING",
                        subCountyID: "120591",
                        subCountyName: "MERU TOWN",
                        wardID: "120591291",
                        wardName: "MERU MUNICIPALITY",
                        accountDesc: "Daily parking",
                        feeDescription: "Saloon Car",
                        transactionCode: null,
                        paidBy: null,
                        paymentMode: null,
                        clampedStatus: "2",
                        clampedTime: "2024-01-04 10:18:16",
                        customerPhoneNumber: null,
                        description:null
                    }
                    ],
                todayHighlights: [
                    {
                        title: "LOGGED VEHICLES",
                        amount: "41,695"
                    }
                ],

                subCounties:[{
                    subCountyID: "",
                    subCountyName: ""
                }],
                wards:[{
                    wardID: "",
                    wardName: ""
                }],
                subCounty:{
                    subCountyID: null,
                    subCountyName: null
                },
                ward:{
                    wardID: null,
                    wardName: null
                },
                zones: [],
                zone:'',
                users: [{
                    id: "1211",
                    names: "Mururu Kennedy Nkunja",
                    gender: "Male",
                    email: "",
                    phoneNumber: "254716802598",
                    idNo: "29046457",
                    password: "$2y$10$2hh5poS1xKRmdNEAmja1gu3.lguP12eenD\/RoIKrmflopk.OPoFkW",
                    category: "COLLECTOR",
                    dateCreated: "2023-12-22 12:07:23",
                    zone: "KIUTINE CESS POINT",
                    subCountyID: "12052",
                    subCountyName: "IGEMBE CENTRAL",
                    wardID: "120520260",
                    wardName: "KANGETA",
                    lat: "-0.0399306",
                    lng: "37.6605822",
                    address: "XM66+267, Embu - Meru Hwy, Mutunduri, Kenya",
                    lastSeen: "2024-01-03 20:27:35",
                    status: "Active",
                    des: "Mururu Kennedy Nkunja logged in at Tue 2 Jan a\t 08:57 PM",
                    target: "0",
                    permission: "READ",
                    dailyTargets: null,
                    monthlyTargets: null,
                    financialYearTargets: null,
                    createdBy: null,
                    deviceId: "23b1e2d8c3a43e10",
                    logTime: "2024-01-02 20:57:44",
                    login: "true"
                }],

                stats: {
                    logged: "696",
                    payers: "0",
                    compliant: "696",
                    uncompliant: "0",
                    clamped: "4",
                    tobeunclamped: "4"
                },
                clampingprogress: [
                    {
                        id: "",
                        billNo: "",
                        dateCreated: "",
                        status: "",
                        description: "",
                        location: ""
                    }]

            }
        },
        mounted() {

            //timeline-carouse
            $("#timeline-carousel").owlCarousel({
                items: 1,
                loop: !1,
                margin: 0,
                nav: !0,
                navText: ["<i class='mdi mdi-chevron-left'></i>", "<i class='mdi mdi-chevron-right'></i>"],
                dots: !1,
                responsive: {
                    576: {
                        items: 2
                    },
                    768: {
                        items: 4
                    }
                },
                startPosition: -1 // display the last item first
            });

            this.dateFrom = this.getCurrentDate()
            this.dateTo =  this.getCurrentDate()

            $('.todays-entries').slick({
                arrows:true,
                prevArrow:'<button type="button" class="slick-prev"></button>',
                nextArrow:'<button type="button" class="slick-next"></button>',
                centerMode:true,
                slidesToShow:7,
                speed: 7000,
                autoplay: true,
                autoplaySpeed: 0,
                cssEase: 'linear',
                slidesToScroll: 1,
                pauseOnFocus: true,
                variableWidth: true
            });

            this.saccoName = sessionStorage.getItem("saccoName")

            this.$watch('search', function (newValue) {
                this.getClampedEntries()
            })
            this.$watch('dateFrom', function (newValue) {
                this.getClampedEntries()
            })
            this.$watch('dateTo', function (newValue) {
                this.getClampedEntries()
            })
            this.getSubCounties()
            this.getClampedEntries()

            this.getUsers()
            this.menuAccess()


        },
        watch: {
            //pagination code
            // define a watcher for the pageSize property
            pageSize(newPageSize) {
                this.getClampedEntries("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },
            subCounty: function () {
                this.subCountyID =  this.subCounty.subCountyID
                this.subCountyName =  this.subCounty.subCountyName
                this.getWards()
            } ,
            ward: function () {
                this.wardID = this.ward.wardID
                this.wardName = this.ward.wardName
            },
        },
        computed: {
            //pagination code
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },
        },
        methods:{
          menuAccess(){

            const data = new FormData();
            data.append("function", "menuAccess");
            data.append("menu", "Clamped Vehicles");
            data.append("sessionNames", sessionStorage.getItem("sessionNames"));
            data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
            execute(data,biller)
                .then(() => {
                  // alert(res.data.message);
                })
                .catch(() => {
                  // alert(e.message);
                });

          },
            closeModal() {
                // Hide the modal using Vue.js logic
                this.$emit('bv::hide::modal', 'myLargeModal');
            },
            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getClampedEntries("")
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getClampedEntries("")
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getClampedEntries("")
                }
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            formattedDate(dateToFormat) {
                const date = new Date(dateToFormat);
                const options = {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                };

                return date.toLocaleString('en-US', options);
            },
            unclampVehicle(){
                const data = new FormData();
                data.append("function", "unclampVehicle");
                data.append("reason", this.reason);
                data.append("billNo", this.vehicle.billNo);
                data.append("unclampedBy", sessionStorage.getItem("names"));
                data.append("unclampedByPhone", sessionStorage.getItem("phoneNumber"));
                data.append("unclampedByIdNo", sessionStorage.getItem("idNo"));
                execute(data,parking)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                           this.getClampedEntries(this.vehicle.numberPlate)
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getClampingProgress(item){
                this.vehicle = item
                const data = new FormData();
                data.append("function", "getClampingProgress");
                data.append("billNo", item.billNo);
                execute(data,parking)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.clampingprogress.splice(0)
                            this.clampingprogress = res.data.data.clampingprogress

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getClampedEntries(search){
                this.loading = true
                search = search === undefined ? '' : search;
                this.vehicles.splice(0)
                const data = new FormData();
                data.append("function", "getClampedEntries");
                data.append("keyword", search);
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("duration","PARK")
                execute(data,parking)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.vehicles = res.data.data.vehicles
                            this.summary = res.data.data.summary
                            this.stats = res.data.data.stats

                            this.totalPages =  Math.ceil(this.summary.count / this.pageSize);

                            this.totalAmount = this.vehicles.reduce((acc, cur) => {
                                let amount = Number(cur.receiptAmount);
                                if (!isNaN(amount)) {
                                    return acc + amount;
                                } else {
                                    return acc;
                                }
                            }, 0);

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            downloadClampedEntries(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadClampedEntries");
                data.append("keyword", this.search);
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("duration","PARK")
                executeDownload(data,parking).then(response => {
                    this.download ="Download Report"

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = "Report.csv";

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounty");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties =res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getWards(){
                this.wards =[]
                this.ward = ''
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID", this.subCounty.subCountyID);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards =res.data.data.wards
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getZones(wardID,wardName){

                wardName = wardName === undefined ? '' : wardName;
                this.getClampedEntries(wardName)

                this.zones.splice(0)
                const data = new FormData();
                data.append("function", "getZones");
                data.append("wardID", wardID);
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.zones = res.data.data.zones
                        }else{
                            this.alert.success = false
                            this.alert.failed = true
                            this.alert.info = false
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                        this.alert.success = false
                        this.alert.failed = true
                        this.alert.info = false
                    });
            },
            getUsers(zone) {
                zone = zone === undefined ? '' : zone;
                this.getClampedEntries(zone)

                this.users.splice(0)
                const data = new FormData();
                data.append("function", "getUsers")
                data.append("search", zone)
                execute(data, authUrl)
                    .then((res) => {
                        if (res.data.success) {

                            this.users = res.data.data.users
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message = e.message
                    });
            },
            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', { month: 'short' });
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            getCurrentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const day = String(today.getDate()).padStart(2, "0");

                return `${year}-${month}-${day}`;
            }
        }
    }
</script>

<style scoped>

</style>